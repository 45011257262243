import { IApiResponse } from 'utils/apiUtils';
import { DropdownOption } from './GeneralTypes';

/** ShiftTemplateStatus
 * The different status values for a Shift Config
 */
export enum ShiftTemplateStatus {
  Draft = 'Draft',
  Live = 'Live',
}

/** ShiftPaymentType
 * The different payment types for a Shift
 * These values should always match the values in the following location:
 * API Common Lib -> Constants -> ShiftConstants --> ShiftPaymentType
 */
export enum ShiftPaymentType {
  Hourly = 'Hourly',
  Fixed = 'Fixed',
}

/** ShiftIndustryType
 * The different types of Industry a Shift can be created for
 * These values should always match the values in the following location:
 * API Common Lib -> Constants -> ShiftConfigConstants --> ShiftIndustryType
 */
export enum ShiftIndustryType {
  HGV = 'HGV',
  Commercial = 'Commercial',
  Industrial = 'Industrial',
}

export interface IShiftConfig {
  isTemplate: boolean;
  templateStatus: ShiftTemplateStatus;
  shiftIndustryType: ShiftIndustryType;
  shiftPaymentType: ShiftPaymentType;
}

export interface IShiftFinance {
  shiftFinanceId: string;
}

export interface IExperienceRequirement {
  ExperienceRequirementId: string;
  ExperienceType: string;
  RequirementType: string;
  MinimumYearsRequired: number;
}

export interface IShiftConfigSaveResponse extends IApiResponse {
  record: IShiftConfig;
  finance: IShiftFinance;
}

type WorkerOptionForConfig = DropdownOption & {
  canApplyForHgvShifts: boolean;
};

export type ShiftTemplateForConfig = DropdownOption & {
  shiftIndistryType: ShiftIndustryType;
  isGeneralHaulage: boolean;
};

type ShiftUiConfigurationHirerViewModel = {
  hirerId: string;
  companyName: string;
  shiftTemplates: ShiftTemplateForConfig[];
};

export type NewShiftConfig = {
  hirerOptions: ShiftUiConfigurationHirerViewModel[];
  workerOptions: WorkerOptionForConfig[];
};
