import { Component } from 'react';
import { Alert, Button, Col, Panel, Row } from 'react-bootstrap';
import $ from 'jquery';
import moment from 'moment';
import { ShiftUtils, PaidStatusBadge } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  Field,
  Icon,
  MapToDiv,
  SmallMuted,
  WellSmall,
} from 'pages/Utils/react-utils';
import { getApprovedListViewConfig, getApprovedShifts } from 'utils/api';
import configureSubscriptions from 'utils/configureSubscriptions';
import DataTable from 'components/DataTable';
import { DisapproveButton } from '../../Hirer/Shift/DisapproveButton';

export class ApprovedShiftsListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      approvableRecords: [],
      recordIdsSelected: [],
      lastGet: null,
      cleanupSubscriptions: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.populateData = this.populateData.bind(this);
    this.getData = this.getData.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
  }

  componentDidMount() {
    this.getConfig();

    const _me = this;
    const { cleanup } = configureSubscriptions(
      _me.subscribeToEvents(),
      _me.subscriber
    );
    this.setState({ cleanupSubscriptions: cleanup });
    this.populateData();
  }

  componentWillUnmount() {
    const { cleanupSubscriptions } = this.state;
    if (cleanupSubscriptions) {
      cleanupSubscriptions();
    }
  }

  subscribeToEvents() {
    return [
      ShiftUtils.Events.ShiftsApproved,
      ShiftUtils.Events.ShiftsDisapproved,
    ];
  }

  subscriber(event) {
    switch (event) {
      case ShiftUtils.Events.ShiftsDisapproved:
        this.setState({ recordIdsSelected: [] }, () => {
          this.populateData();
        });
        break;

      case ShiftUtils.Events.ShiftsApproved:
        this.populateData();
        break;

      default:
        return;
    }
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });

    const data = await getApprovedListViewConfig();
    if (data.configuration) {
      this.setState({ configuration: data.configuration });
    }
    this.setState({ isGettingConfig: false });
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  handleSearchClick() {
    this.populateData();
  }

  async populateData() {
    this.setIsGetting(true);

    const data = await this.getData();
    if (data.records) {
      // const canApproveTimeForHirerIds = this.state.configuration.hirers
      //   .filter((hirer) => hirer.canAgencyApproveWorkerTime)
      //   .map((hirer) => hirer.hirerId);
      const recordsToSet = data.records.map((record) => {
        return {
          ...record,
          // canApprove: canApproveTimeForHirerIds.indexOf(record.hirerId) > -1,
          canApprove: true,
        };
      });
      this.setState({
        records: recordsToSet,
        approvableRecords: recordsToSet.filter((record) => record.canApprove),
      });
    }
    this.setIsGetting(false, new Date());
  }

  getDataModel() {
    const dataModel = AppUtils.getDataModel($('#ApprovedFilterContainer'));
    return dataModel;
  }

  async getData() {
    return getApprovedShifts(this.getDataModel());
  }

  getApprovableRecordById(id) {
    return this.state.approvableRecords.find((record) => record.shiftId === id);
  }

  handleCheckboxChange(id) {
    if (id === 'all') {
      return this.handleCheckboxSelectAllClick();
    }

    let recordIdsSelected = [...this.state.recordIdsSelected];
    const index = recordIdsSelected.indexOf(id);
    if (index > -1) {
      recordIdsSelected.splice(index, 1);
    } else {
      // Check record can be selected
      const record = this.getApprovableRecordById(id);
      if (!record) {
        AppUtils.displayError('Error', 'Could not find selected record', true);
        return;
      }

      recordIdsSelected.push(id);
    }
    this.setState({ recordIdsSelected }, this.processUiUpdates);
  }

  handleCheckboxSelectAllClick() {
    const getRecordIdsToBeSelected = () => {
      if (
        this.state.recordIdsSelected.length ===
        this.state.approvableRecords.length
      ) {
        return [];
      }
      return this.state.approvableRecords.map((record) => record.shiftId);
    };
    this.setState(
      { recordIdsSelected: getRecordIdsToBeSelected() },
      this.processUiUpdates
    );
    return;
  }

  processUiUpdates() {
    const $table = $(`#${tableId}`);
    $table.find('.selected').removeClass('selected');
    $table.find('input[type="checkbox"]').prop('checked', false);
    $table.find('#checkbox_approved_all').prop('checked', false);

    this.state.recordIdsSelected.forEach((recordId) => {
      const $checkbox = $(`#checkbox_${recordId}`);
      $checkbox.prop('checked', true);
      $checkbox.closest('tr').addClass('selected');
    });

    if (
      this.state.approvableRecords.length > 0 &&
      this.state.approvableRecords.length ===
        this.state.recordIdsSelected.length
    ) {
      $table.find('#checkbox_approved_all').prop('checked', true);
    }
  }

  render() {
    return (
      <>
        <Alert bsStyle='success'>
          <Icon icon='fa-check' /> Shifts which have been Completed and the
          shift times have been approved.
        </Alert>
        <Filters {...this.state} handleSearchClick={this.handleSearchClick} />
        <TableContainer
          {...this.props}
          {...this.state}
          handleCheckboxChange={this.handleCheckboxChange}
        />
      </>
    );
  }
}

const Filters = ({ isGetting, handleSearchClick, configuration }) => {
  const hirerOptions =
    configuration?.hirers?.map((hirer) => ({
      value: hirer.hirerId,
      text: hirer.companyName,
    })) ?? [];
  return (
    <Panel id='ApprovedFilterContainer'>
      <Panel.Body>
        <Row>
          <DatePicker
            columns={4}
            name='FromDate'
            label='Date From'
            value={AppUtils.getDateString(moment().subtract(30, 'days'))}
            required
            notInFormGroup
          />
          <DatePicker
            columns={4}
            name='ToDate'
            label='Date To'
            value={AppUtils.getDateString(moment())}
            required
            notInFormGroup
          />
          <Col sm={4}>
            <Field.Main
              name={`HirerId`}
              label='Customer'
              options={hirerOptions}
              isSelect
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={isGetting}
          handleSearchClick={handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({ isGetting, handleSearchClick }) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const tableId = 'approved-shifts-table';
const TableContainer = ({
  isGetting,
  lastGet,
  records,
  recordIdsSelected,
  approvableRecords,
  handleCheckboxChange,
  setDisplayShiftId,
}) => {
  if (isGetting) {
    return (
      <WellSmall className='m-b-none' hasSpinner>
        Searching for Approved Shifts...
      </WellSmall>
    );
  }
  if (records.length <= 0 && !lastGet) {
    return null;
  }

  if (records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>No Approved Shifts found.</WellSmall>
    );
  }
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={6}>Results</Col>
          <Col sm={6} className='text-right'>
            <DisapproveButton
              records={records}
              recordIdsSelected={recordIdsSelected}
            />
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <DataTable
          tableId={tableId}
          records={records}
          render={() => {
            if (records.length <= 0) {
              return (
                <WellSmall className='m-b-none'>
                  No Approved shifts found.
                </WellSmall>
              );
            }

            const isRecordSelected = (id) => recordIdsSelected.indexOf(id) > -1;
            const areAllSelected =
              approvableRecords.length > 0 &&
              approvableRecords.length === recordIdsSelected.length;

            return (
              <table
                className='table table-condensed table-striped table-hover'
                id={tableId}
                data-order='[[ 1, "asc" ]]'
              >
                <thead>
                  <tr>
                    <th className='no-sort'>
                      <Checkbox
                        id='checkbox_approved_all'
                        defaultChecked={areAllSelected}
                        onChange={() => handleCheckboxChange('all')}
                      />
                    </th>
                    <th>Details</th>
                    <th>When</th>
                    <th>Worker</th>
                    <th>Type</th>
                    <th>
                      Times Approved
                      <i
                        className='fa fa-info-circle m-l text-primary'
                        data-toggle='tooltip'
                        title='These are the approved times'
                      />
                    </th>
                    <th>Approved</th>
                    <th>Payment Amount</th>
                    <th className='no-sort' />
                  </tr>
                </thead>
                <tbody>
                  {records.map((item, i) => (
                    <TableRow
                      key={i}
                      record={item}
                      isSelected={isRecordSelected(item.shiftId)}
                      setDisplayShiftId={setDisplayShiftId}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  ))}
                </tbody>
              </table>
            );
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

const TableRow = ({
  record,
  setDisplayShiftId,
  isSelected,
  handleCheckboxChange,
}) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        {record.canApprove && (
          <Checkbox
            id={`checkbox_${record.shiftId}`}
            defaultChecked={isSelected}
            onChange={() => handleCheckboxChange(record.shiftId)}
          />
        )}
      </td>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td>{record.shiftPaymentType}</td>
      <td>
        {AppUtils.formatTime(record.shiftTimeStartActualConfirmed)} -{' '}
        {AppUtils.formatTime(record.shiftTimeEndActualConfirmed)}
      </td>
      <td data-order={record.approvedDateTime}>
        {AppUtils.formatDateTimeUtc(record.approvedDateTime)}
        <div>
          <SmallMuted>by {record.approvedBy}</SmallMuted>
        </div>
      </td>
      <td data-order={record.paymentAmount}>
        £{AppUtils.formatNumber(record.paymentAmount)}
        <div>
          <PaidStatusBadge record={record} />
        </div>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};
