import { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { approveShift } from 'utils/api';
import { ErrorAlert } from 'components/ErrorAlert';

export class ApproveSelectedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isGeneratingFinancials: false,
      isModalShowing: false,
    };

    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  handleShowModalClick() {
    if (this.props.recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
    } else {
      this.setState({ isModalShowing: true });
    }
  }

  handleCloseModalClick() {
    this.setState({ isModalShowing: false });
  }

  async handleSubmitClick() {
    let model = {
      shiftIds: this.props.recordIdsSelected,
    };

    const _me = this;
    _me.setState({ isSaving: true });
    const data = await approveShift(model);
    AppUtils.handleAjaxDone(
      data,
      async () => {
        AppUtils.displaySuccess('Done', 'Shifts Approved.');
        _me.setState({ isSaving: false });
        _me.handleCloseModalClick();
        PubSub.publish(ShiftUtils.Events.ShiftsApproved, data);
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  }

  onExited() {
    AppUtils.destroyTooltips();
  }

  render() {
    const recordsSelectedCount = this.props.recordIdsSelected.length;
    const plural = recordsSelectedCount > 1 ? 's' : '';
    const btnText = `Approve ${
      recordsSelectedCount === 0
        ? ''
        : `${recordsSelectedCount} Shift${plural}...`
    }`;

    const hasShiftsWhichRequireChargeUpdate = this.props.recordIdsSelected.some(
      (shiftId) => {
        return this.props.records.find((shift) => shift.shiftId === shiftId)
          .linkedShiftRequiresChargeUpdate;
      }
    );

    return (
      <>
        <Button
          bsStyle='success'
          bsSize='sm'
          onClick={this.handleShowModalClick}
          disabled={recordsSelectedCount <= 0}
        >
          <Icon icon='fa-thumbs-up' /> {btnText}
        </Button>
        <Modal
          backdrop='static'
          show={this.state.isModalShowing}
          onHide={this.handleCloseModalClick}
          onExited={this.onExited}
        >
          <Modal.Header closeButton>
            <Modal.Title>Approve Selected Shifts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Body
              {...this.state}
              {...this.props}
              recordsSelectedCount={recordsSelectedCount}
              plural={plural}
              hasShiftsWhichRequireChargeUpdate={
                hasShiftsWhichRequireChargeUpdate
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModalClick} bsSize='sm'>
              <i className='fa fa-times' /> Close
            </Button>
            <SubmitButton
              {...this.state}
              disabled={hasShiftsWhichRequireChargeUpdate}
              handleSubmitClick={this.handleSubmitClick}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const Body = ({
  recordsSelectedCount,
  plural,
  hasShiftsWhichRequireChargeUpdate,
}) => {
  return (
    <>
      <p>
        You have chosen to approve{' '}
        <span className='text-success'>{recordsSelectedCount}</span> Shift
        {plural}.
      </p>

      {hasShiftsWhichRequireChargeUpdate ? (
        <ErrorAlert
          msg={
            'Some of the shifts you are trying to approve are linked to shifts with no charge information. Please update the charge information and try again'
          }
        />
      ) : (
        <Alert bsStyle='info'>Are you sure you would like to proceed?</Alert>
      )}
    </>
  );
};

const SubmitButton = (props) => {
  const buttonTitle = props.isGeneratingFinancials
    ? 'Generating Financials...'
    : props.isSaving
    ? 'Approving...'
    : 'Yes, Approve';

  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving || props.disabled}
    >
      <Icon icon='fa-thumbs-up' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
