import { Route, Switch } from 'utils/router';
import { ShiftEdit } from '../Shift/Edit';
import { ShiftConfigEdit } from '../ShiftConfig/Edit/index';
import { ShiftConfigListView } from '../ShiftConfig/ListView';
import NotFoundPage from '../not-found';
import { GeneralHaulageDaily } from './GeneralHaulageDaily';
import HirerEdit from './Hirer/Edit/HirerEdit';
import HirerAdmin from './Hirer/HirerAdmin';
import { InvoiceSingle, InvoicesMain } from './Invoice';
import AgencyEdit from './Profile/Edit/AgencyEdit';
import { ReportingAdmin } from './ReportingAdmin';
import ShiftAdmin from './Shift/ShiftAdmin';
import { ShiftCalculator } from './ShiftCalculator';
import { ShiftDaily } from './ShiftDaily';
import { ShiftPlanner } from './ShiftPlanner';
import { ShiftsUploadMileage, ShiftsUploadTimes } from './ShiftUploads';
import { ShiftsCompleted } from './ShiftsCompleted';
import { Uploads } from './Uploads';
import { VehicleAdmin } from './Vehicle/VehicleAdmin';
import { VehiclePlanner } from './VehiclePlanner';
import WorkerProfile from './Worker/Profile/Profile';
import WorkerAdmin from './Worker/WorkerAdmin';

const AgencyRoutes = () => {
  return (
    <Switch>
      <Route exact path='/Shift' component={ShiftAdmin} />
      <Route exact path='/Shift/Completed' component={ShiftsCompleted} />
      <Route exact path='/Uploads' component={Uploads} />
      <Route
        exact
        path='/Shift/UploadMileage'
        component={ShiftsUploadMileage}
      />
      <Route exact path='/Shift/UploadTimes' component={ShiftsUploadTimes} />
      <Route exact path='/Shift/Daily' component={ShiftDaily} />
      <Route
        exact
        path='/Shift/GeneralHaulage'
        component={GeneralHaulageDaily}
      />
      <Route exact path='/Shift/Calculator' component={ShiftCalculator} />
      <Route exact path='/Shift/Edit/:shiftId' component={ShiftEdit} />
      <Route exact path='/Shift/Planner' component={ShiftPlanner} />
      <Route exact path='/Vehicle/Planner' component={VehiclePlanner} />
      <Route exact path='/ShiftConfig' component={ShiftConfigListView} />
      <Route
        exact
        path='/ShiftConfig/Edit/:shiftConfigId'
        component={ShiftConfigEdit}
      />
      <Route exact path='/Agency/Profile' component={AgencyEdit} />
      <Route exact path='/Worker' component={WorkerAdmin} />
      <Route exact path='/Worker/Profile/:workerId' component={WorkerProfile} />
      <Route exact path='/Hirer' component={HirerAdmin} />
      <Route exact path='/Hirer/Edit/:hirerId' component={HirerEdit} />
      <Route exact path='/Vehicle' component={VehicleAdmin} />
      <Route exact path='/Reporting' component={ReportingAdmin} />
      <Route exact path='/Invoice' component={InvoicesMain} />
      <Route exact path='/Invoice/:invoiceId' component={InvoiceSingle} />
      <Route path='*' component={NotFoundPage} />
    </Switch>
  );
};

export default AgencyRoutes;
