import { Col, Row } from 'react-bootstrap';
import { FormLabel } from 'components';
import { Checkbox } from 'pages/Utils/Checkbox';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field } from 'pages/Utils/react-utils';
import { TabIntro } from '../../ShiftConfigCommon';
import { useShiftConfigContext } from '../ShiftConfigContextProvider';
import ShiftDetailsHgvSpecificFields from './ShiftDetailsHgvSpecificFields';

const ShiftDetailsTab = () => {
  const { configuration, record, isHgvShift, disabled, handleChange } =
    useShiftConfigContext();
  const shiftPaymentTypeOptions = Object.keys(
    configuration.shiftPaymentTypeOptions
  ).map((item) => configuration.shiftPaymentTypeOptions[item]);
  return (
    <>
      <TabIntro icon='fa-2x fa-map-marked-alt'>
        Configure the headline details for this shift.
      </TabIntro>
      <Row>
        <Field.InCol
          columns={4}
          name='Name'
          label='Template Name'
          value={record.name}
          maxLength='100'
          disabled={disabled}
        />
        <Field.InCol
          columns={4}
          name='ShiftIndustryType'
          label='Industry Type'
          value={record.shiftIndustryType}
          disabled
          tooltip='This defines which types of workers will see this shift when searching'
        />
        <Field.InCol
          columns={4}
          name='ShiftPaymentType'
          label='Shift Payment Type'
          value={record.shiftPaymentType}
          options={shiftPaymentTypeOptions}
          isSelect
          notClearable
          onChange={handleChange}
          disabled={disabled}
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='TypicalShiftLengthHours'
          label='Typical Shift Length'
          value={record.typicalShiftLengthHours}
          maxLength='2'
          addOnPost='Hours'
          isNumber
          disabled={disabled}
        />
        <Col sm={8}>
          <FormLabel
            label='Guaranteed Shift Length'
            name='GuaranteedShiftLengthHours'
          />
          <i
            className='fa fa-info-circle m-l text-primary'
            data-toggle='tooltip'
            title='The duration entered will be used to check if worker has worked the minimum guaranteed shift length. If the actual length is less than the guaranteed length, they will be paid up to the guaranteed length for the shift.'
          />
          <Row>
            <Field.InCol
              columns={6}
              name='GuaranteedShiftLengthHours'
              value={record.guaranteedShiftLengthHours}
              maxLength='2'
              isNumber
              noLabel
              addOnPost='Hours'
              disabled={disabled}
            />
            <Field.InCol
              columns={6}
              name='GuaranteedShiftLengthMinutes'
              value={record.guaranteedShiftLengthMinutes}
              maxLength='2'
              isNumber
              noLabel
              addOnPost='Minutes'
              disabled={disabled}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={4}>
          <TimeDropdown
            name='ShiftTime'
            label='Default Start Time'
            value={record.shiftTime}
            placeholder='Default Start Time...'
            required
            disabled={disabled}
          />
        </Col>
        <Checkbox
          columns={4}
          id='IsVehicleRequired'
          label={`Is vehicle required?`}
          checked={record.isVehicleRequired}
          disabled={disabled}
        />
        <Checkbox
          columns={4}
          id='IsGeneralHaulage'
          label={
            <>
              <span>General Haulage Shift?</span>
              <i
                className='fa fa-info-circle m-l text-primary'
                data-toggle='tooltip'
                title='This will restrict shifts from being shown in profit reports and will also affect the way the shift is displayed in the worker app'
              />
            </>
          }
          checked={record.isGeneralHaulage}
          disabled={disabled}
          tooltip='General Haulage shifts are typically longer and involve more drops'
        />
      </Row>

      <hr />
      <Row>
        <Field.InCol
          columns={6}
          type='number'
          name='TypicalDropQty'
          label='Typcial Number of Drops'
          value={record.typicalDropQty}
          maxLength='3'
          addOnPost='Drops'
          isNumber
          disabled={disabled}
          onlyRenderWhen={isHgvShift}
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='Description'
          value={record.description}
          maxLength='200'
          disabled={disabled}
        />
      </Row>

      {isHgvShift ? <ShiftDetailsHgvSpecificFields /> : null}

      <hr />
      <Row>
        <Field.InCol
          columns={12}
          name='HealthAndSafetyOptions'
          label='Health and Safety'
          value={record.healthAndSafetyOptions}
          options={AppUtils.options.healthAndSafetyOptions}
          isSelect
          multiple
          disabled={disabled}
        />
      </Row>
    </>
  );
};

export default ShiftDetailsTab;
