import { ReactNode, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ChangePasswordLink } from 'pages/App/ChangePasswordLink';
import HirerAgencyInvites from 'pages/Hirer/AgencyInvites';
import { Icon } from 'pages/Utils/react-utils';
import layoutUpdates from 'utils/appSetup';
import { requireAuth, useAuth } from 'utils/auth';
import { userPermissions, usePermissions } from 'utils/userPermissions';

const MainLayout = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    layoutUpdates();
  }, []);

  const auth = useAuth();
  const isSignedIn = Boolean(auth?.user?.record);
  if (!isSignedIn) {
    return null;
  }

  const userRecord = auth.user.record;

  return (
    <div id='wrapper'>
      <div className='border-bottom'>
        <nav
          className='navbar navbar-fixed-top navbar-static-top white-bg'
          role='navigation'
          style={{ marginBottom: 0 }}
        >
          <HomeLink />
          <div className='navbar-header'>
            <a
              className='navbar-minimalize minimalize-styl-2 btn btn-primary'
              href='#openMenu'
            >
              <i className='fas fa-fw fa-bars'></i>
            </a>
          </div>
          <ul className='nav navbar-top-links navbar-right'>
            {userRecord.isHirerUser && (
              <li className='dropdown agency-invites-container'>
                <HirerAgencyInvites />
              </li>
            )}

            {userRecord.isAgencyUser && (
              <li className='dropdown agency-invites-container'>
                <Link to='/agency/profile'>
                  <Icon icon='fa-fw fa-cog' />
                  Admin
                </Link>
              </li>
            )}

            {isSignedIn && (
              <li>
                <SignOutLink />
              </li>
            )}
          </ul>
        </nav>
      </div>

      <nav className='navbar-default navbar-static-side' role='navigation'>
        <div className='sidebar-collapse'>
          <ul className='nav metismenu' id='side-menu'>
            <li className='nav-header'>
              <div className='dropdown profile-element'>
                <a
                  data-toggle='dropdown'
                  className='dropdown-toggle'
                  href='#openUserMenu'
                >
                  <span className='clear'>
                    <span className='block m-t-xs'>
                      <strong className='font-bold'>
                        {auth.user.record.displayName}
                      </strong>
                    </span>
                    <span className='text-muted text-xs block'>
                      {auth.user.record.jobTitle} <b className='caret'></b>
                    </span>
                  </span>
                </a>
                <ul className='dropdown-menu animated fadeInRight m-t-xs'>
                  <li className='change-password-conatiner'>
                    <ChangePasswordLink />
                  </li>

                  {userRecord.isHirerUser && (
                    <li>
                      <Link to={`/Profile`}>
                        <Icon icon='fa-fw fa-forklift' />{' '}
                        <span className='nav-label'> Company Profile</span>
                      </Link>
                    </li>
                  )}

                  <li>
                    <SignOutLink />
                  </li>
                </ul>
              </div>
            </li>

            <SubMenu
              style={{ marginTop: '20px' }}
              title=''
              subMenuItems={[
                {
                  title: 'Home',
                  link: '/',
                  icon: 'fa-home',
                },
              ]}
            />

            {userRecord.isAdminUser && (
              <>
                <li>
                  <Link to='/Agency'>
                    <Icon icon='fa-fw fa-handshake' />{' '}
                    <span className='nav-label'> Agencies</span>
                  </Link>
                </li>

                <li>
                  <Link to='/User'>
                    <Icon icon='fa-fw fa-user-chart' />{' '}
                    <span className='nav-label'> Users</span>
                  </Link>
                </li>
              </>
            )}

            {userRecord.isAgencyUser && (
              <>
                <SubMenu
                  title='Ops'
                  subMenuItems={[
                    {
                      title: 'Ops Planner',
                      link: '/Shift/Daily',
                      icon: 'fa-calendar',
                    },
                    {
                      title: 'General Haulage',
                      link: '/Shift/GeneralHaulage',
                      icon: 'fa-truck',
                    },
                    {
                      title: 'Shifts',
                      link: '/Shift',
                      icon: 'fa-user-hard-hat',
                    },
                    {
                      title: 'Completed Shifts',
                      link: '/Shift/Completed',
                      icon: 'fa-user-check',
                    },
                  ]}
                />

                <SubMenu
                  title='Planning'
                  subMenuItems={[
                    {
                      title: 'Shift Planner',
                      link: '/Shift/Planner',
                      icon: 'fa-list-ul',
                      requiredPermission: userPermissions.shiftPlanner.view,
                    },
                    {
                      title: 'Vehicle Planner',
                      link: '/Vehicle/Planner',
                      icon: 'fa-list-ul',
                      requiredPermission: userPermissions.vehiclePlanner.view,
                    },
                  ]}
                />

                <SubMenu
                  title='Admin'
                  subMenuItems={[
                    {
                      title: 'Shift Templates',
                      link: '/ShiftConfig',
                      icon: 'fa-list-alt',
                      requiredPermission: userPermissions.shiftTemplate.view,
                    },
                    {
                      title: 'Workers',
                      link: '/Worker',
                      icon: 'fa-person-dolly-empty',
                    },
                    {
                      title: 'Customers',
                      link: '/Hirer',
                      icon: 'fa-forklift',
                    },
                    {
                      title: 'Vehicles',
                      link: '/Vehicle',
                      icon: 'fa-truck',
                    },
                    {
                      title: 'Uploads',
                      link: '/Uploads',
                      icon: 'fa-cloud-upload',
                    },
                    {
                      title: 'Job Calculator',
                      link: '/Shift/Calculator',
                      icon: 'fa-calculator',
                    },
                  ]}
                />

                <SubMenu
                  title='Finance'
                  subMenuItems={[
                    {
                      title: 'Reports',
                      link: '/Reporting',
                      icon: 'fa-chart-bar',
                      requiredPermission: userPermissions.shift.finance.view,
                    },
                    {
                      title: 'Invoices',
                      link: '/Invoice',
                      icon: 'fa-file-invoice-dollar',
                      requiredPermission: userPermissions.shift.finance.view,
                    },
                  ]}
                />
              </>
            )}

            {userRecord.isHirerUser && (
              <li>
                <Link to='/Shift'>
                  <Icon icon='fa-fw fa-user-hard-hat' />{' '}
                  <span className='nav-label'> Shifts</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>

      <div id='page-wrapper' className='gray-bg'>
        <div>{children}</div>

        <div className='footer'>
          <div className='pull-right' style={{ fontSize: '10px' }}>
            Built by{' '}
            <a
              href='https://8fifty4.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              8fifty4 Software
            </a>
          </div>
          <div>
            <strong>Copyright</strong> Rightjob/HGVMe
          </div>
        </div>
      </div>
    </div>
  );
};

type SubMenuItem = {
  title: string;
  link: string;
  icon: string;
  requiredPermission?: string;
};

const SubMenu = ({
  title,
  subMenuItems,
  style,
}: {
  title: string;
  subMenuItems: SubMenuItem[];
  style?: React.CSSProperties;
}) => {
  const { hasPermission } = usePermissions();
  const subMenuItemsFiltered = useMemo(() => {
    return subMenuItems.filter((item) => {
      if (!item.requiredPermission) {
        return true;
      }
      return hasPermission(item.requiredPermission);
    });
  }, [hasPermission, subMenuItems]);

  if (!subMenuItemsFiltered.length) {
    return null;
  }

  return (
    <li style={style}>
      <ul className='nav'>
        {title ? (
          <li>
            <h6>{title}</h6>
          </li>
        ) : null}

        {subMenuItemsFiltered.map((item) => (
          <li key={item.link}>
            <Link to={item.link}>
              <Icon icon={`fa-fw ${item.icon}`} />
              <span className='nav-label'> {item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

const HomeLink = () => {
  const auth = useAuth();
  const logoStyle = useMemo(() => {
    if (auth.user.record.logoPath) {
      return {
        backgroundImage: `url(${encodeURI(auth.user.record.logoPath)})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        backgroundPosition: '50% 50%',
      };
    } else {
      return {};
    }
  }, [auth.user.record.logoPath]);

  return (
    <Link
      to='/'
      id='logo-link'
      className='home-link pull-left'
      style={logoStyle}
    >
      HOME
    </Link>
  );
};

const SignOutLink = () => {
  const auth = useAuth();
  return (
    <Link
      to='/auth/signout'
      onClick={(e) => {
        e.preventDefault();
        auth.signout();
      }}
    >
      <Icon icon='fa-fw fa-sign-out' /> Sign Out
    </Link>
  );
};

export default requireAuth(MainLayout);
