import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { vehicleApi } from 'apis/vehicleApi';
import {
  CreateVehicleParams,
  GetVehicleSummaryParams,
  SaveVehicleParams,
} from 'types/VehicleTypes';

export const useCreateVehicleMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: CreateVehicleParams) => {
      return vehicleApi.createVehicle(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehiclesActive']);
    },
  });

  return mutation;
};

export const useEditVehicleMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: SaveVehicleParams) => {
      return vehicleApi.editVehicle(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehiclesActive']);
    },
  });

  return mutation;
};

export const useArchiveVehicleMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof vehicleApi.archiveVehicle>[0]) => {
      return vehicleApi.archiveVehicle(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehiclesActive']);
    },
  });

  return mutation;
};

export const useGetVehiclesActiveQuery = () => {
  return useQuery({
    queryKey: ['getVehiclesActive'],
    queryFn: vehicleApi.getVehiclesActive,
    staleTime: 60000,
  });
};

export const useGetVehicleSummaryQuery = (params: GetVehicleSummaryParams) => {
  return useQuery({
    queryKey: ['getVehicleSummary', params.vehicleId, params.shiftDate],
    queryFn: () => vehicleApi.getVehicleSummary(params),
    enabled: !!params.vehicleId,
  });
};
