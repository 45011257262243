import { useState } from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { useGetDailyGeneralHaulageViewQuery } from 'hooks/queries';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { SmallInfo } from 'pages/Utils/react-utils';
import { GeneralHaulageDailyViewItem } from 'types/ShiftTypes';
import DataTable from 'components/DataTable';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { MapComponent } from 'components/Map';
import './style.scss';

export const GeneralHaulageDaily = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const query = useGetDailyGeneralHaulageViewQuery({
    date: selectedDate,
  });
  return (
    <>
      {query.isSuccess ? (
        <MapComponent
          linkedShifts={query.data.map((s) => {
            return s.linkedShifts.map((ls) => {
              return {
                ...ls,
                workerName: s.workerName,
              };
            });
          })}
        />
      ) : null}
      <Panel>
        <Panel.Heading>
          <Row>
            <Col sm={8}>
              <Panel.Title componentClass='h3'>
                General Haulage Daily View
              </Panel.Title>
            </Col>
            <Col sm={4}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span style={{ flexBasis: '56px' }}>Date:</span>
                <div style={{ flexGrow: 1 }}>
                  <DatePicker
                    name={`StartDate`}
                    value={AppUtils.getDateString(selectedDate)}
                    changeDate={(e: { date: Date }) => {
                      setSelectedDate(e.date);
                    }}
                    noLabel
                    notInFormGroup
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          <ShiftTable query={query} />
        </Panel.Body>
      </Panel>
    </>
  );
};

const _tableId = 'shift-daily-view-table';

type QueryType = ReturnType<typeof useGetDailyGeneralHaulageViewQuery>;

const ShiftTable = ({ query }: { query: QueryType }) => {
  const { isSuccess, isLoading, isError, error, data } = query;

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <ErrorAlertForQueryOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    return (
      <DataTable
        records={data}
        tableId={_tableId}
        render={() => {
          return (
            <table
              className='general-haulage-daily-table table table-condensed'
              id={_tableId}
              data-order='[[ 0, "asc" ]]'
            >
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>When</th>
                  <th style={{ width: '15%' }}>Who</th>
                  <th style={{ width: '15%' }}>Status</th>
                  <th style={{ width: '15%' }}>Shifts</th>
                </tr>
              </thead>
              <tbody>
                {data.map((record, i) => (
                  <TableRow key={i} record={record} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    );
  }

  return null;
};

const TableRow = ({ record }: { record: GeneralHaulageDailyViewItem }) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime, undefined, 'HH:mm')}
      </td>
      <td data-order={record.workerSurname}>
        {`${record.workerName}`}
        {record.vehicleReg ? (
          <>
            <br />
            <SmallInfo>{record.vehicleReg}</SmallInfo>
          </>
        ) : null}
      </td>
      <td>{record.status}</td>
      <td>
        {record.linkedShifts.map((linkedShift) => {
          return (
            <div key={linkedShift.shiftId} className='linked-shift-container'>
              <div>
                {linkedShift.companyName} - {linkedShift.destinationPostcode}
                <br />
                <SmallInfo>{linkedShift.status}</SmallInfo>
              </div>
              <div>
                <a
                  href={`/Shift/Edit/${linkedShift.shiftId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Open &raquo;
                </a>
                {/* <Link to={`/Shift/Edit/${record.shiftId}`}></Link> */}
              </div>
            </div>
          );
        })}
      </td>
    </tr>
  );
};
