export type ShiftTrailer = {
  shiftId: string;
  trailerId: string;
  trailerRef: string;
};

export type AddTrailerToShiftParams = {
  shiftId: string;
  trailerId: string;
};

export type RemoveTrailerFromShiftParams = {
  shiftId: string;
  trailerId: string;
};

export type AddNoteToShiftParams = {
  shiftId: string;
  comments: string;
  subject: string;
  isAvailableToWorker: boolean;
  shiftNoteType: ShiftNoteType;
};

export type ShiftNote = {
  shiftNoteId: string;
  shiftId: string;
  comments: string;
  isAvailableToWorker: boolean;
  createdBy: string;
  createdDateTime: string;
};

export type ShiftNoteType = 'Worker' | 'Hirer';

export type ShiftFile = {
  shiftFileId: string;
  fileUrl: string;
  createdBy: string;
  createdDateTime: string;
};

export type ShiftSegment = {
  shiftSegmentId: string;
  order: number;
  name: string;
  postcode: boolean;
  distanceMiles: number;
};

export type AddPaymentAdjustmentToShiftParams = {
  shiftId: string;
  reason: string;
  amount: number;
};

export type CompleteShiftParams = {
  shiftId: string;
};

export type AuthoriseSingleShiftParams = {
  shiftId: string;
};

export type ShiftPaymentAdjustment = {
  shiftPaymentAdjustmentId: string;
  shiftId: string;
  reason: string;
  amount: number;
  createdBy: string;
  createdDateTime: string;
};

export type UpdateVehicleParams = {
  shiftId: string;
  vehicleId: string;
};

export type UpdateShiftChargeRateParams = {
  shiftId: string;
  chargeAmount: number;
};

export type GetDailyViewQueryParams = {
  hirerId: string;
  date: Date;
};

export type GetDailyGeneralHaulageViewQueryParams = {
  date: Date;
};

export type GeneralHaulageDailyViewItem = {
  linkedShifts: GeneralHaulageDailyViewItemLinkedShift[];
  shiftId: string;
  status: string;
  shiftDate: Date;
  shiftTime: string;
  workerName: string;
  workerSurname: string;
  vehicleReg: string;
};

export type GeneralHaulageDailyViewItemLinkedShift = {
  shiftId: string;
  destinationPostcode: string;
  companyName: string;
  status: string;
};

export type DailyViewNoteItem = {
  noteId: string;
  comments: string;
  isAvailableToWorker: boolean;
  createdBy: string;
  createdDateTime: Date;
};

export type DailyViewItem = {
  shiftId: string;
  status: string;
  shiftDate: Date;
  shiftTime: string;
  notes: DailyViewNoteItem[];
  workerName: string;
  workerSurname: string;
  vehicleReg: string;
  trailerRefs: string[];
};

export type GetApprovedShiftsParams = {
  [key: string]: string;
  hirerId: string;
};

export type GetPaidShiftsParams = {
  hirerId: string;
  fromDate: string;
  toDate: string;
};

export type GetUnpaidShiftsParams = {
  hirerId: string;
  fromDate: string;
  toDate: string;
};

export type GetPayrollReportParams = {
  hirerId: string;
  year: string;
  weekNumber: string;
};

export type GetPayrollCsvParams = {
  shiftIds: string[];
};

export type Shift = {
  hirerId: string;
  companyName: string;
  shiftId: string;
  status: string;
  name: string;
  workerFirstName: string;
  workerSurname: string;
  shiftDate: Date;
  shiftTime: string;
  paymentAmount: number;
  approvedDateTime: string;
  approvedBy: string;
  shiftDateEndActualConfirmed: string;
  shiftDateStartActualConfirmed: string;
  shiftTimeEndActualConfirmed: string;
  shiftTimeStartActualConfirmed: string;
  hasPaymentAdjustments: boolean;
  paymentAdjustmentAmount: number;
  paymentAuthRequestedDateTime: string;
  paymentAuthRequestedBy: string;
};

export type UnpaidShift = {
  shiftId: string;
  status: string;
  companyName: string;
  workerFirstName: string;
  workerSurname: string;
  name: string;
  shiftDate: Date;
  shiftTime: string;
  paymentAuthorisedDateTime: string;
  paymentAuthorisedBy: string;
  paymentAmount: number;
};

export type PayrollReportShift = UnpaidShift & {
  isPaid: boolean;
  paymentReference: string;
};

export type UnpaidContractorShift = Omit<
  UnpaidShift,
  'paymentAuthorisedDateTime' | 'paymentAuthorisedBy'
> & {
  contractorEmailSentDateTime: string;
};

export type PaidShift = {
  paymentReference: string;
  shiftId: string;
  status: string;
  shiftDate: string;
  shiftTime: string;
  companyName: string;
  workerFirstName: string;
  workerSurname: string;
  name: string;
  paidDateTime: string;
  paidBy: string;
  paymentAmount: number;
};

export type AddShiftSegmentParams = {
  shiftId: string;
  locationId: string;
};

export type AddRdcShiftSegmentParams = {
  shiftId: string;
};

export type DeleteShiftSegmentParams = {
  shiftId: string;
  shiftSegmentId: string;
};

export type MileageUploadSummary = {
  shiftId: string;
  shiftDateTime: string;
  reg: string;
  shiftName: string;
};

export type MileageUploadResponse = {
  summaries: MileageUploadSummary[];
};

export type TimesUploadSummary = {
  shiftId: string;
  shiftDateTime: string;
  worker: string;
  shiftName: string;
  fromDateTime: string;
  toDateTime: string;
};

export type TimesUploadResponse = {
  summaries: TimesUploadSummary[];
};

export type PushShiftConfigToShiftParams = {
  shiftConfigId: string;
};

export type LinkShiftParams = {
  shiftId: string;
  hirerId: string;
  shiftTemplateId: string;
  shiftTime: string;
};

export type LinkedShift = {
  shiftId: string;
  shiftDate: string;
  shiftTime: string;
  hirerName: string;
  shiftName: string;
  chargeAmountFixedOverride: number;
  isPrimaryShiftGeneralHaulage: boolean;
  destinationPostcode: string;
  order: number;
  status: string;
};

export type MarkShiftsAsPaidParams = {
  shiftIds: string[];
  // paymentReference: string;
};

export type CalculatorInputData = {
  desiredMarginPercentage: number;
  driverPayAmount: number;
  averageMilesPerGallon: number;
  fuelCostPerLitre: number;
  adBlueCostPerMile: number;
  tyreCostPerMile: number;
  fridgeFuelCost: number;
};

export type CalculatorParams = {
  hirerId: string;
  vehicleId: string;
  trailerId: string;
} & CalculatorInputData;

export type CalculationResponse = {
  chargeAmount: number;
  distanceInMiles: number;
  fuelCost: number;
  tyreCost: number;
  adBlueCost: number;
  vehicleFixedCost: number;
  totalVehicleCost: number;
  totalTrailerCost: number;
  totalCost: number;
  driverPayAmount: number;
  fridgeFuelCost: number;
};

export enum ReorderType {
  Increase = 'Increase',
  Decrease = 'Decrease',
}
