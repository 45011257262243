import { useState, useMemo } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useAddNoteToShiftMutation, useShiftNotesQuery } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { RadioButton } from 'pages/Utils/RadioButton';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import { ShiftNote, ShiftNoteType } from 'types/ShiftTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Table } from 'components/Table';

const _formId = 'add-note-container';

export const ShiftNotesUi = ({
  shiftId,
  displayToWorkerDefault = false,
  addBtnSize = 'sm',
  addBtnClassName,
  addBtnContainerClassName,
  showCreatedByColumn = true,
  showCreatedDateTimeColumn = true,
  type = 'Worker',
}: {
  shiftId: string;
  displayToWorkerDefault?: boolean;
  addBtnSize?: 'sm' | 'xs';
  addBtnClassName?: string;
  addBtnContainerClassName?: string;
  showCreatedByColumn?: boolean;
  showCreatedDateTimeColumn?: boolean;
  type?: ShiftNoteType;
}) => {
  const [isAddShowing, setIsAddShowing] = useState(false);

  const { mutate, isError, error, isLoading } = useAddNoteToShiftMutation();

  const queryClient = useQueryClient();

  const handleSaveClick = () => {
    const $container = $('#' + _formId + type);

    if (!AppUtils.validateItems($container.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel($container) as {
      Subject: string;
      Comments: string;
      IsAvailableToWorker: string;
    };

    const params = {
      shiftId,
      comments: dataModel.Comments,
      subject: dataModel.Subject,
      isAvailableToWorker: dataModel?.IsAvailableToWorker === 'true' ?? false,
      shiftNoteType: type,
    };
    mutate(params, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ShiftNotes],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VehiclePlanForHirer],
        });

        AppUtils.displaySuccess('Success', 'Note added');

        // Close the note field
        setIsAddShowing(false);
      },
    });
  };

  return (
    <>
      {isAddShowing ? (
        <>
          <div id={_formId + type}>
            {type === 'Hirer' ? (
              <Row>
                <Field.InCol
                  columns={12}
                  name='Subject'
                  label='Subject:'
                  maxLength={500}
                />
              </Row>
            ) : null}
            <Row>
              <Field.InCol
                columns={12}
                name='Comments'
                label='Comments:'
                required
                isTextArea
                maxLength={1000}
              />
            </Row>
            {type === 'Worker' ? (
              <Row>
                <Col sm={12}>
                  <div
                    style={{
                      borderRadius: '1px',
                      border: '1px solid #e5e6e7',
                      padding: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <RadioButton
                      name='IsAvailableToWorker'
                      options={[
                        {
                          text: 'Yes',
                          value: 'true',
                        },
                        {
                          text: 'No',
                          value: 'false',
                        },
                      ]}
                      value={
                        displayToWorkerDefault !== undefined
                          ? displayToWorkerDefault.toString()
                          : 'false'
                      }
                      label='Display to worker?'
                    />
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>

          <Row>
            <Col sm={12} style={{ textAlign: 'right' }} className='m-b-sm'>
              <Button
                bsStyle='danger'
                bsSize='sm'
                onClick={() => setIsAddShowing(false)}
                disabled={isLoading}
                className='m-r-sm'
              >
                <Icon icon='fa-times' isSpinning={isLoading} /> Cancel
              </Button>
              <Button
                bsStyle='primary'
                bsSize='sm'
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                <Icon icon='fa-save' isSpinning={isLoading} /> Save and Send
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={addBtnContainerClassName}>
          <Button
            bsStyle='primary'
            bsSize={addBtnSize}
            className={addBtnClassName}
            onClick={() => setIsAddShowing(true)}
          >
            <Icon icon='fa-plus' /> Add Note
          </Button>
        </div>
      )}

      {isError ? (
        <Row>
          <Col xs={12}>
            <ErrorAlert msg={extractErrorMessages(error)} />
          </Col>
        </Row>
      ) : null}

      <hr />
      <NotesTable
        shiftId={shiftId}
        type={type}
        showCreatedByColumn={showCreatedByColumn}
        showCreatedDateTimeColumn={showCreatedDateTimeColumn}
      />
    </>
  );
};

const NotesTable = ({
  shiftId,
  showCreatedByColumn = true,
  showCreatedDateTimeColumn = true,
  type,
}: {
  shiftId: string;
  showCreatedByColumn?: boolean;
  showCreatedDateTimeColumn?: boolean;
  type: ShiftNoteType;
}) => {
  const { data, isError, error, isSuccess, isLoading } = useShiftNotesQuery(
    shiftId,
    type
  );

  const columns = [
    {
      Header: 'Comments',
      accessor: (record: ShiftNote) => record,
      Cell: ({
        value,
      }: {
        value: {
          subject: string;
          comments: string;
          isAvailableToWorker: boolean;
          type: ShiftNoteType;
        };
      }) => {
        return (
          <>
            {value.subject ? (
              <>
                <strong>{value.subject}</strong>
                <br />
              </>
            ) : null}
            {type === 'Worker' ? (
              <i
                className={`fa fa-${
                  value.isAvailableToWorker ? 'eye' : 'lock'
                } m-r text-primary`}
                data-toggle='tooltip'
                title={
                  value.isAvailableToWorker
                    ? 'Visible by worker'
                    : 'Not visible by worker'
                }
              />
            ) : null}
            {value.comments}
          </>
        );
      },
    },
    showCreatedByColumn
      ? {
          Header: 'Created By',
          accessor: 'createdBy',
          width: '20%',
        }
      : null,
    showCreatedDateTimeColumn
      ? {
          Header: 'When',
          accessor: (record: ShiftNote) => record.createdDateTime,
          Cell: ({ value }: { value: string }) =>
            AppUtils.formatDateTimeUtc(value),
          width: '20%',
        }
      : null,
  ].filter(Boolean);

  const records = useMemo(() => data ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isSuccess && records.length > 0) {
    return <Table columns={columns} records={records} />;
  }

  if (isSuccess && records.length <= 0) {
    return <Alert>No notes found.</Alert>;
  }

  return null;
};
