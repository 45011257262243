import { AppUtils } from 'pages/Utils/app-utils';
import { Field } from 'pages/Utils/react-utils';
import { Trailer } from 'types/TrailerTypes';
import { Vehicle } from 'types/VehicleTypes';

type NumberFieldProps =
  | {
      type: 'vehicle';
      record: Vehicle;
      name: keyof Vehicle;
      label: string;
    }
  | {
      type: 'trailer';
      record: Trailer;
      name: keyof Trailer;
      label: string;
    };

export const NumberField = ({
  type,
  record,
  name,
  label,
}: NumberFieldProps) => {
  const value = type === 'vehicle' ? record[name] : record[name];
  return (
    <Field.InCol
      columns={3}
      name={name}
      label={label}
      value={AppUtils.formatNumber(value)}
      maxLength='9'
      isNumber
      addOnPre='£'
      addOnPost={'Per Day'}
      required
      disabled
      isControlled
    />
  );
};
