import { Component } from 'react';
import { Alert, Button, Col, Row, Well } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import logo from 'assets/images/RightJobLogo.png';
import $ from 'jquery';
import moment from 'moment';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  AlertWithIcon,
  Icon,
  InputField,
  WellSmall,
} from 'pages/Utils/react-utils';
import { getHirerUserInviteById, reviewHirerUserInvite } from 'utils/api';
import { useAuth } from 'utils/auth';
import './style.scss';

const _status = {
  invited: 'Invited',
  accepted: 'Accepted',
  rejected: 'Rejected',
};

const HirerUserInviteReview = () => {
  const params = useParams();
  return <Main hirerUserInviteId={params?.hirerUserInviteId} />;
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hirerUserInviteId: props.hirerUserInviteId,
      isGetting: true,
      model: null,
      gettingModelError: null,
      isSaving: false,
      submitError: null,
      wasSaved: false,
      decision: null,
      confirmedEmailAddress: null,
    };

    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleRejectClick = this.handleRejectClick.bind(this);
  }

  componentDidMount() {
    this.getData();

    $('body').addClass('hirer-user-invite-review-body');
  }

  setIsGetting(isGetting) {
    this.setState({ isGetting });
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getHirerUserInviteById(this.state.hirerUserInviteId);
    if (data.model) {
      this.setState({ model: data.model });
    }
    this.setIsGetting(false);
  }

  handleAcceptClick(e) {
    console.log(`handleAcceptClick`);
    this.handleReview(e, _status.accepted);
  }

  handleRejectClick(e) {
    console.log(`handleRejectClick`);
    this.handleReview(e, _status.rejected);
  }

  async handleReview(e, decision) {
    let model = {
      HirerUserInviteId: this.state.hirerUserInviteId,
      decision,
    };

    if (decision === _status.accepted) {
      if (!AppUtils.validateItems($('.required'))) {
        return false;
      }

      const $password = $('#Password');
      const $passwordConfirm = $('#PasswordConfirm');
      if ($password.val() !== $passwordConfirm.val()) {
        $password.addClass('invalid');
        $passwordConfirm.addClass('invalid');
        AppUtils.displayError('Password Error', 'Passwords do not match.');
        return false;
      }

      const data = AppUtils.getDataModel();
      model = { ...model, ...data };
    }

    this.setState({ isSaving: true, decision, submitError: null });

    const data = await reviewHirerUserInvite(model);
    if (data.status === AppUtils.ApiStatus.Success) {
      AppUtils.displaySuccess(
        'Thank you',
        this.state.decision === _status.accepted
          ? 'You accepted the invitation'
          : 'You rejected the invitation'
      );
      this.setState({
        wasSaved: true,
        confirmedEmailAddress: data.emailAddress,
      });
    }
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Row className='animated fadeInDown'>
        <Col xs={10} xsOffset={1} sm={6} smOffset={3} lg={4} lgOffset={4}>
          <div>
            <img
              src={logo}
              alt='RightJob Logo'
              className='img-responsive'
              style={{ margin: '0 auto', maxWidth: '500px' }}
            />
          </div>

          <IsStillSignedIn />

          <InviteReviewMain
            {...this.state}
            handleAcceptClick={this.handleAcceptClick}
            handleRejectClick={this.handleRejectClick}
          />

          <hr />

          <p className='m-t text-center'>
            <small>
              RightJob &copy;{' '}
              {AppUtils.formatDateTimeUtc(moment(), null, 'YYYY')}
            </small>
          </p>
        </Col>
      </Row>
    );
  }
}

const IsStillSignedIn = () => {
  const auth = useAuth();
  const isSignedIn = Boolean(auth?.user?.record);
  if (!isSignedIn) {
    return null;
  }

  return (
    <AlertWithIcon bsStyle='warning' icon='fa-user'>
      {`You're still signed in. `}
      <Link
        to={document.location.pathname}
        onClick={(e) => {
          e.preventDefault();
          auth.signout();
          window.location.reload();
        }}
      >
        Sign Out here to try again.
      </Link>{' '}
    </AlertWithIcon>
  );
};

const GettingModel = (props) => {
  if (!props.isGetting) {
    return null;
  }
  return <WellSmall hasSpinner>Getting invite details...</WellSmall>;
};

const GettingModelError = (props) => {
  if (!props.gettingModelError) {
    return null;
  }
  return <Alert bsStyle='danger'>{props.gettingModelError}</Alert>;
};

const InviteReviewMain = (props) => {
  if (props.isGetting) {
    return <GettingModel {...props} />;
  }
  if (props.gettingModelError) {
    return <GettingModelError {...props} />;
  }
  if (!props.model) {
    return null;
  }

  return (
    <Row
      style={{
        backgroundColor: 'white',
        padding: '20px 10px',
        borderRadius: '5px',
      }}
    >
      <Col sm={12}>
        <Intro {...props} />

        <SubmitError {...props} />

        <AcceptedUi {...props} />
        <RejectedUi {...props} />
        <InviteReviewDataCaptureUi {...props} />
      </Col>
    </Row>
  );
};

const Intro = ({ model }) => {
  let title, msg;
  if (model.isExistingHirer) {
    title = `User Invite`;
    msg = (
      <span>
        You have been invited to join <strong>{model.companyName}</strong> by{' '}
        <strong>{model.createdBy}</strong>.
      </span>
    );
  } else {
    title = `Customer Invite`;
    msg = (
      <span>
        You have been invited to become a customer of{' '}
        <strong>{model.agencyName}</strong>.
      </span>
    );
  }
  return (
    <Well className='text-center bg-primary'>
      <h1 className='m-t-none text-white'>{title}</h1>
      <p className='m-b-none'>{msg}</p>
    </Well>
  );
};

const InviteReviewDataCaptureUi = (props) => {
  if (props.wasSaved || props.model.status !== _status.invited) {
    return null;
  }
  const model = props.model;
  return (
    <>
      <CompanyInput {...props} />

      <Row>
        <Col sm={6}>
          <InputField
            name='FirstName'
            label='First Name'
            value={model.firstName}
            required
          />
        </Col>
        <Col sm={6}>
          <InputField name='Surname' value={model.surname} required />
        </Col>
      </Row>

      <InputField
        name='EmailAddress'
        label='Email Address'
        value={model.emailAddress}
        disabled
      />

      <Row>
        <Col sm={6}>
          <InputField name='Password' type='password' required />
        </Col>
        <Col sm={6}>
          <InputField
            name='PasswordConfirm'
            label='Confirm Password'
            type='password'
            required
          />
        </Col>
      </Row>

      <Well>
        <Row>
          <Col xs={4}>
            <RejectButton {...props} />
          </Col>
          <Col xs={8}>
            <AcceptButton {...props} />
          </Col>
        </Row>
      </Well>
    </>
  );
};

const CompanyInput = ({ model }) => {
  if (model.isExistingHirer) {
    return null;
  }
  return (
    <InputField
      name='CompanyName'
      label='Company Name'
      value={model.companyName}
      required
    />
  );
};

const SubmitError = (props) => {
  if (!props.submitError) {
    return null;
  }
  return <Alert bsStyle='danger'>{props.submitError}</Alert>;
};

const AcceptedUi = (props) => {
  const isStatusAccepted = props.model.status === _status.accepted;
  const isSavedAndIsDecisionAccepted =
    props.wasSaved && props.decision === _status.accepted;
  if (!isStatusAccepted && !isSavedAndIsDecisionAccepted) {
    return null;
  }
  return (
    <Well>
      <Row>
        <Col sm={6}>
          <span className='text-success'>
            <Icon icon='fa-check' /> Invitation Accepted.
          </span>
        </Col>
        <Col sm={6} className='text-right'>
          <Link
            to={`/auth/signin?emailAddress=${
              props.confirmedEmailAddress || ''
            }`}
            className='btn btn-primary'
          >
            <Icon icon='fa-sign-in' /> Sign in
          </Link>
        </Col>
      </Row>
    </Well>
  );
};

const RejectedUi = (props) => {
  const isStatusRejected = props.model.status === _status.rejected;
  const isSavedAndIsDecisionRejected =
    props.wasSaved && props.decision === _status.rejected;
  if (!isStatusRejected && !isSavedAndIsDecisionRejected) {
    return null;
  }
  return (
    <Well>
      <Row>
        <Col sm={6}>
          <span className='text-danger'>
            <Icon icon='fa-times' /> Invitation Rejected.
          </span>
        </Col>
        <Col sm={6} className='text-right'>
          <a className='btn btn-primary' href='https://www.hgvme.com'>
            <i className='fas fa-home'></i> Home
          </a>
        </Col>
      </Row>
    </Well>
  );
};

const RejectButton = (props) => {
  return (
    <Button
      bsStyle='danger'
      block
      className='full-width'
      onClick={(e) => props.handleRejectClick(e)}
      disabled={props.isSaving}
    >
      <Icon isSpinning={props.isSaving} icon='fa-times' /> Reject
    </Button>
  );
};

const AcceptButton = (props) => {
  return (
    <Button
      bsStyle='success'
      block
      className='full-width'
      onClick={(e) => props.handleAcceptClick(e)}
      disabled={props.isSaving}
    >
      <Icon isSpinning={props.isSaving} icon='fa-check' /> Accept Invitation
    </Button>
  );
};

export default HirerUserInviteReview;
