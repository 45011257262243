import { useState } from 'react';
import { Button, Col, Panel, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { Dropdown } from 'components';
import { useSaveShiftCalculatorLocationMutation } from 'hooks/queries';
import { useGetLocationsByHirerQuery } from 'hooks/queries/locationQueries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, InputField } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Stack } from 'components/Stack';
import { CalculatorLocationsList } from './CalculatorLocationsList';

export const CalculatorLocations = ({ hirerId }: { hirerId: string }) => {
  const [isFormShowing, setIsFormShowing] = useState(false);

  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Icon icon='fa-map' /> Locations
          </Col>
          {!isFormShowing ? (
            <Col sm={4} style={{ textAlign: 'right' }}>
              <Button
                bsStyle='primary'
                bsSize='xs'
                onClick={() => setIsFormShowing(true)}
              >
                <Icon icon='fa-plus' /> Add Location
              </Button>
            </Col>
          ) : null}
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <Stack gap={10}>
          {isFormShowing ? (
            <CalculatorLocationForm
              hirerId={hirerId}
              setIsFormShowing={setIsFormShowing}
            />
          ) : null}

          <CalculatorLocationsList hirerId={hirerId} />
        </Stack>
      </Panel.Body>
    </Panel>
  );
};

const CalculatorLocationForm = ({
  hirerId,
  setIsFormShowing,
}: {
  hirerId: string;
  setIsFormShowing: (val: boolean) => void;
}) => {
  const query = useGetLocationsByHirerQuery({
    hirerId,
  });

  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    null
  );

  const [postcode, setPostcode] = useState<string | null>(null);

  const [selectedLocation, setSelectedLocation] = useState<{
    name: string;
    postcode: string;
    distanceMiles: number;
  } | null>(null);

  const handleSelectLocation = (locationId: string) => {
    if (!locationId) {
      // They've pressed the clear button
      setSelectedLocationId(null);
      setSelectedLocation(null);
      return;
    }

    // Get location and write to state
    const location = query.data?.find(
      (location) => location.locationId === locationId
    );

    if (!location) {
      AppUtils.displayError(
        'Error',
        'Could not get location. Please try again.'
      );
      return;
    }

    setSelectedLocationId(locationId);

    setSelectedLocation({
      name: location.name,
      postcode: location.postcode,
      distanceMiles: location.distanceMiles,
    });
  };

  const { mutate, isError, error, isLoading } =
    useSaveShiftCalculatorLocationMutation();

  const queryClient = useQueryClient();
  const handleSaveClick = () => {
    if (!selectedLocationId && !postcode) {
      AppUtils.displayError(
        'Error',
        'Please select a location or enter a postcode'
      );
      return;
    }

    let params: Parameters<typeof mutate>[0];
    if (hirerId && hirerId === 'NoCustomerSelected') {
      // No customer selected
      params = {
        isManual: true,
        locationId: AppUtils.createGuid(),
        locationName: postcode,
        postcode,
        hirerId,
      };
    } else if (hirerId && postcode) {
      // No customer selected
      params = {
        isManual: true,
        locationId: AppUtils.createGuid(),
        locationName: postcode,
        postcode,
        hirerId,
      };
    } else if (hirerId && selectedLocationId) {
      params = {
        isManual: false,
        locationId: selectedLocationId,
        locationName: selectedLocation?.name ?? '',
        postcode: selectedLocation?.postcode ?? '',
        hirerId,
      };
    }

    mutate(params, {
      onSuccess: () => {
        setSelectedLocationId(null);
        setSelectedLocation(null);
        setPostcode(null);
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CalculatorLocations],
        });
      },
    });
  };

  return (
    <>
      <Row>
        {hirerId && hirerId !== 'NoCustomerSelected' ? (
          <Col sm={6}>
            <LocationSelect
              selectedLocationId={selectedLocationId}
              handleSelectLocation={handleSelectLocation}
              hirerId={hirerId}
            />
          </Col>
        ) : null}

        <Col sm={6}>
          <InputField
            isControlled
            label='Enter Postcode'
            value={postcode}
            onChange={(e: ICustomEvent) => {
              setPostcode(e.target.value);
            }}
          />
        </Col>
      </Row>

      {isError ? (
        <Row>
          <Col xs={12}>
            <ErrorAlert msg={extractErrorMessages(error)} />
          </Col>
        </Row>
      ) : null}

      <Stack direction='row' style={{ justifyContent: 'flex-end' }}>
        <Button
          bsStyle='danger'
          bsSize='sm'
          onClick={() => setIsFormShowing(false)}
          disabled={isLoading}
          className='m-r-sm'
        >
          <Icon icon='fa-times' isSpinning={isLoading} /> Cancel
        </Button>
        <Button
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSaveClick}
          disabled={isLoading}
        >
          <Icon icon='fa-save' isSpinning={isLoading} /> Save Location
        </Button>
      </Stack>

      <hr />
    </>
  );
};

const LocationSelect = ({
  selectedLocationId,
  handleSelectLocation,
  hirerId,
}: {
  selectedLocationId: string | null;
  handleSelectLocation: (selectedLocationId: string) => void;
  hirerId: string;
}) => {
  const query = useGetLocationsByHirerQuery({
    hirerId,
  });

  if (query.isLoading) {
    return <span>Loading...</span>;
  }

  if (query.isSuccess) {
    const data = query.data ?? [];
    const options = data.map((location) => ({
      text: location.name,
      value: location.locationId,
    }));

    return (
      <Dropdown
        value={selectedLocationId}
        label='Choose Location'
        options={options}
        onChange={(e: ICustomEvent) => {
          handleSelectLocation(e.target.value);
        }}
      />
    );
  }
};
