import { useState } from 'react';
import { Panel, Row, Col, Alert, Well } from 'react-bootstrap';
import { useSelectedCustomer } from 'hooks';
import {
  useCalculateMutation,
  useTrailerOptions,
  useVehicleOptions,
} from 'hooks/queries';
import { useGetVehicleCostConfigQuery } from 'hooks/queries/agencyQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, InputField, WellSmall } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { CalculationResponse, CalculatorInputData } from 'types/ShiftTypes';
import { Button } from 'components/Button';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { CalculatorLocations } from './CalculatorLocations';
import { TrailerSection } from './TrailerSection';
import { VehicleSection } from './VehicleSection';
import './_style/shiftCalculatorStyles.scss';

export const ShiftCalculator = () => {
  const vehicleCostConfigQuery = useGetVehicleCostConfigQuery();
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Panel.Title componentClass='h3'>Job Calculator</Panel.Title>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        {vehicleCostConfigQuery.isLoading ? (
          <WellSmall hasSpinner>Getting vehicle cost config</WellSmall>
        ) : (
          <CalculatorMain data={vehicleCostConfigQuery.data} />
        )}
      </Panel.Body>
    </Panel>
  );
};

const friendlyNames: { [key in keyof CalculatorInputData]: string } = {
  desiredMarginPercentage: '% Margin Percentage',
  driverPayAmount: 'Worker Pay Amount Total',
  averageMilesPerGallon: 'Average MPG',
  fuelCostPerLitre: 'Fuel Cost Per Litre',
  adBlueCostPerMile: 'AdBlue Cost Per Mile',
  tyreCostPerMile: 'Tyre Cost Per Mile',
  fridgeFuelCost: 'Fridge Fuel Cost',
} as const;

const CalculatorMain = ({
  data,
}: {
  data: {
    averageMilesPerGallon: number;
    fuelCostPerLitre: number;
    adBlueCostPerMile: number;
    tyreCostPerMile: number;
  };
}) => {
  const selectedCustomerProps = useSelectedCustomer();
  const hirerId =
    Boolean(selectedCustomerProps.selectedCustomerId) === false
      ? 'NoCustomerSelected'
      : selectedCustomerProps.selectedCustomerId;
  const [vehicleId, setVehicleId] = useState<string | null>(null);
  const [trailerId, setTrailerId] = useState<string | null>(null);
  const [responseData, setResponseData] = useState<CalculationResponse | null>(
    null
  );

  const handleSetVehicleId = (value: string | null) => {
    setResponseData(null);
    setVehicleId(value);
  };

  const handleSetTrailerId = (value: string | null) => {
    setResponseData(null);
    setTrailerId(value);
  };

  const [inputData, setInputData] = useState<CalculatorInputData | null>({
    desiredMarginPercentage: 10,
    driverPayAmount: 200,
    averageMilesPerGallon: data.averageMilesPerGallon,
    fuelCostPerLitre: data.fuelCostPerLitre,
    adBlueCostPerMile: data.adBlueCostPerMile,
    tyreCostPerMile: data.tyreCostPerMile,
    fridgeFuelCost: 0,
  });

  const handleUpdateProperty = (
    property: keyof typeof inputData,
    value: string
  ) => {
    setInputData({
      ...inputData,
      [property]: value,
    });
  };

  const calculate = useCalculateMutation();
  const vehiclesQuery = useVehicleOptions();
  const trailersQuery = useTrailerOptions();
  const isLoading =
    vehiclesQuery.query.isLoading || trailersQuery.query.isLoading;

  if (isLoading) {
    return <WellSmall hasSpinner>Getting vehicles and trailers</WellSmall>;
  }

  const vehicle = vehiclesQuery.vehicleData.find(
    (vehicle) => vehicle.vehicleId === vehicleId
  );

  const trailer = trailersQuery.trailerData.find(
    (trailer) => trailer.trailerId === trailerId
  );

  const handleCalculateClick = () => {
    const validateInputData = () => {
      for (const [key, value] of Object.entries(inputData)) {
        if (value.toString() === '' || isNaN(value)) {
          AppUtils.displayError(
            'Error',
            `Please enter a valid number for ${
              friendlyNames[key as keyof CalculatorInputData]
            }`
          );
          return false;
        }
      }
      return true;
    };
    if (!validateInputData()) {
      return false;
    }

    setResponseData(null);

    calculate.mutate(
      {
        hirerId,
        vehicleId,
        trailerId,
        ...inputData,
      },
      {
        onSuccess: (data) => {
          setResponseData(data);
        },
      }
    );
  };

  return (
    <>
      <Row>
        <Field.InCol
          columns={3}
          name='AverageMilesPerGallon'
          label='Average MPG'
          value={inputData.averageMilesPerGallon}
          maxLength='5'
          isNumber
          required
          tooltip='Average Miles Per Gallon. This is used to calculate the fuel costs when a vehicle is used on a shift.'
          isControlled
          onChange={(e: ICustomEvent) => {
            handleUpdateProperty('averageMilesPerGallon', e.target.value);
          }}
        />
        <Field.InCol
          columns={3}
          name='FuelCostPerLitre'
          label='Fuel Cost Per Litre'
          value={inputData.fuelCostPerLitre}
          maxLength='9'
          isNumber
          addOnPre='£'
          addOnPost={'Per Litre'}
          required
          isControlled
          onChange={(e: ICustomEvent) => {
            handleUpdateProperty('fuelCostPerLitre', e.target.value);
          }}
        />
        <Field.InCol
          columns={3}
          name='TyreCostPerMile'
          label='Tyre Cost Per Mile'
          value={inputData.tyreCostPerMile}
          maxLength='9'
          isNumber
          addOnPre='£'
          addOnPost={'Per Mile'}
          required
          isControlled
          onChange={(e: ICustomEvent) => {
            handleUpdateProperty('tyreCostPerMile', e.target.value);
          }}
        />
        <Field.InCol
          columns={3}
          name='AdBlueCostPerMile'
          label='AdBlue Cost Per Mile'
          value={inputData.adBlueCostPerMile}
          maxLength='9'
          isNumber
          addOnPre='£'
          addOnPost={'Per Mile'}
          required
          isControlled
          onChange={(e: ICustomEvent) => {
            handleUpdateProperty('adBlueCostPerMile', e.target.value);
          }}
        />
        <Field.InCol
          columns={3}
          name='FridgeFuelCost'
          label='Fridge Fuel Cost'
          value={inputData.fridgeFuelCost}
          maxLength='9'
          isNumber
          addOnPre='£'
          required
          isControlled
          onChange={(e: ICustomEvent) => {
            handleUpdateProperty('fridgeFuelCost', e.target.value);
          }}
        />
      </Row>

      <VehicleSection
        setVehicleId={handleSetVehicleId}
        vehicle={vehicle}
        vehicleId={vehicleId}
        vehicleOptions={vehiclesQuery.vehicleOptions}
      />

      {vehicle ? (
        <>
          <TrailerSection
            setTrailerId={handleSetTrailerId}
            trailer={trailer}
            trailerId={trailerId}
            trailerOptions={trailersQuery.trailerOptions}
          />

          <Row className='m-b'>
            <Col sm={4}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span style={{ flexBasis: '56px' }}>Customer:</span>
                <div style={{ flexGrow: 1 }}>
                  {selectedCustomerProps.renderCustomerSelect()}
                </div>
              </div>
            </Col>
          </Row>

          <CalculatorLocations hirerId={hirerId} />

          <hr />
          <Row>
            <Col sm={4}>
              <InputField
                isControlled
                label='% Margin Required'
                value={inputData?.desiredMarginPercentage}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty(
                    'desiredMarginPercentage',
                    e.target.value
                  );
                }}
              />
            </Col>
            <Col sm={4}>
              <InputField
                isControlled
                label='Worker Pay Amount Total'
                value={inputData?.driverPayAmount}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty('driverPayAmount', e.target.value);
                }}
              />
            </Col>
          </Row>
          <hr />
          <Button
            onClick={handleCalculateClick}
            bsStyle='primary'
            isLoading={calculate.isLoading}
          >
            Calculate
          </Button>

          <ErrorAlertForQueryOrNull
            className='m-t'
            isError={calculate.isError}
            error={calculate.error}
          />

          {responseData ? (
            <Well className='m-t'>
              <Stack gap={30}>
                <div>
                  <h4>Results</h4>
                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={6}>
                          <Card
                            title='Charge Amount'
                            value={responseData.chargeAmount}
                          />
                        </Col>
                        <Col sm={6}>
                          <Card
                            title='Total Cost'
                            value={responseData.totalCost}
                            bsStyle='danger'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6}>
                      <Row>
                        <Col sm={4}>
                          <Card
                            title='Total Vehicle Cost'
                            value={responseData.totalVehicleCost}
                            bsStyle='info'
                          />
                        </Col>
                        <Col sm={4}>
                          <Card
                            title='Total Trailer Cost'
                            value={responseData.totalTrailerCost}
                            bsStyle='info'
                          />
                        </Col>
                        <Col sm={4}>
                          <Card
                            title='Driver Pay'
                            value={responseData.driverPayAmount}
                            bsStyle='info'
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div>
                  <h4>Breakdown</h4>
                  <p>
                    <strong>Distance In Miles:</strong>{' '}
                    {AppUtils.formatNumber(responseData.distanceInMiles)}
                  </p>

                  <ResultRow label='Fuel' value={responseData.fuelCost} />
                  <ResultRow label='Tyres' value={responseData.tyreCost} />
                  <ResultRow label='Ad Blue' value={responseData.adBlueCost} />
                  <ResultRow
                    label='Fridge Fuel'
                    value={responseData.fridgeFuelCost}
                  />
                  <ResultRow
                    label='Vehicle Fixed Cost (day)'
                    value={responseData.vehicleFixedCost}
                  />
                  <ResultRow
                    label='Total Vehicle Costs'
                    value={responseData.totalVehicleCost}
                  />
                  <ResultRow
                    label='Total Trailer Costs'
                    value={responseData.totalTrailerCost}
                  />
                  <ResultRow
                    label='Driver Pay'
                    value={responseData.driverPayAmount}
                  />
                  <ResultRow
                    label='Total Costs'
                    value={responseData.totalCost}
                  />
                  <ResultRow
                    label='Charge Amount'
                    value={responseData.chargeAmount}
                  />
                </div>
              </Stack>
            </Well>
          ) : null}
        </>
      ) : null}
    </>
  );
};

const Card = ({
  bsStyle = 'success',
  title,
  value,
}: {
  title: string;
  value: number;
  bsStyle?: string;
}) => {
  return (
    <Alert bsStyle={bsStyle}>
      <div className='result-card'>
        <h3>{title}</h3>
        <p>{AppUtils.getCurrencyVal(value)}</p>
      </div>
    </Alert>
  );
};

const ResultRow = ({ label, value }: { label: string; value: number }) => {
  return (
    <p>
      <strong>{label}:</strong> {AppUtils.getCurrencyVal(value)}
    </p>
  );
};
