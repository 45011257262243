import { Row } from 'react-bootstrap';
import { AlertWithIcon, Field } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { Vehicle } from 'types/VehicleTypes';
import { nameof } from 'utils';
import { NumberField as NumberFieldCommon } from './Components';

const getName = (fieldName: keyof Vehicle) => nameof<Vehicle>(fieldName);

type VehicleSectionProps = {
  vehicleId: string;
  setVehicleId: (value: string) => void;
  vehicleOptions: {
    text: string;
    value: string;
  }[];
  vehicle: Vehicle;
};

export const VehicleSection = ({
  vehicleId,
  setVehicleId,
  vehicleOptions,
  vehicle,
}: VehicleSectionProps) => {
  return (
    <>
      <Row>
        <Field.InCol
          columns={3}
          name={`VehicleId`}
          label={`Vehicle`}
          options={vehicleOptions}
          isSelect
          value={vehicleId}
          onChange={(e: ICustomEvent) => {
            setVehicleId(e.target.value);
          }}
        />

        {vehicle ? (
          <>
            <Field.InCol
              columns={3}
              name={getName('make')}
              label='Make'
              value={vehicle.make}
              maxLength='50'
              disabled
              isControlled
            />
            <Field.InCol
              columns={3}
              name={getName('model')}
              label='Model'
              value={vehicle.model}
              maxLength='50'
              disabled
              isControlled
            />
          </>
        ) : null}
      </Row>

      {vehicleId && !vehicle ? (
        <AlertWithIcon>
          Vehicle not found. Please select a valid vehicle.
        </AlertWithIcon>
      ) : null}

      {!vehicleId ? (
        <AlertWithIcon>Please select a vehicle.</AlertWithIcon>
      ) : null}

      {vehicle ? (
        <>
          <h4>Vehicle Costs</h4>
          <hr />
          <Row>
            <NumberField
              record={vehicle}
              name='rentalCostPerDay'
              label='Rental'
            />
            <NumberField
              record={vehicle}
              name='insuranceCostPerDay'
              label='Insurance'
            />
            <NumberField
              record={vehicle}
              name='washAllowancePerDay'
              label='Wash Allowance'
            />
            <NumberField
              record={vehicle}
              name='trackerCostPerDay'
              label='Tracker'
            />
            <NumberField
              record={vehicle}
              name='depreciationAllowancePerDay'
              label='Depreciation Allowance'
            />
            <NumberField
              record={vehicle}
              name='telematicsCostPerDay'
              label='Telematics'
            />
            <NumberField
              record={vehicle}
              name='damageAllowancePerDay'
              label='Damage Allowance'
            />
          </Row>

          <hr />
        </>
      ) : null}
    </>
  );
};

const NumberField = (props: {
  record: Vehicle;
  name: keyof Vehicle;
  label: string;
}) => {
  return <NumberFieldCommon {...props} type='vehicle' />;
};
