import { Component } from 'react';
import { Alert, Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { HirerUtils } from 'pages/Agency/Hirer/HirerUtils';
import HirerUserTable from 'pages/Hirer/Profile/Edit/HirerUsersTable';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  Field,
  WellSmall,
  DashboardHeader,
  Muted,
  Icon,
  SidebarData,
} from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getHirerEditConfig, getHirerById, saveHirer } from 'utils/api';
import { ContentWithSidebarWrapper, Sidebar } from 'components/Sidebar';
import { HirerContextProvider, useHirerContext } from './HirerEditContext';
import { LocationsTab } from './LocationsTab';

const HirerEdit = () => {
  return (
    <HirerContextProvider>
      <HirerEditMain />
    </HirerContextProvider>
  );
};

const HirerEditMain = () => {
  const { hirerId } = useHirerContext();
  return <Main hirerId={hirerId} />;
};

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingRecord: false,
      record: null,
      isGettingConfig: false,
      configuration: null,
      activeTab: 1,
      isSaving: false,
      errors: [],
    };

    this.subscriber = this.subscriber.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getRecord();
    this.getConfig();

    const _me = this;
    this.subscribeToEvents().forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscribeToEvents() {
    return [HirerUtils.Events.Saved];
  }

  subscriber(event, data) {
    switch (event) {
      case HirerUtils.Events.Saved:
        this.setState({
          record: data.record,
        });
        return;

      default:
        return;
    }
  }

  async getRecord() {
    this.setState({ isGettingRecord: true, errors: [] });
    const data = await getHirerById(this.props.hirerId);
    AppUtils.handleAjaxDone(data, () => {
      if (data.record) {
        this.setState({ record: data.record });
      } else {
        throw new Error('Could not get record');
      }
    });
    this.setState({ isGettingRecord: false });
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });
    const data = await getHirerEditConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        this.setState({ configuration: data.configuration });
      } else {
        throw new Error('Could not get configuration.');
      }
    });
    this.setState({ isGettingConfig: false });
  }

  handleChange(e) {
    const { record } = this.state;
    record[e.target.name.toCamelCase()] = e.target.value;
    this.setState({ record });
  }

  setIsSaving(val) {
    this.setState({ isSaving: val });
  }

  async save() {
    const _me = this;

    let model = AppUtils.getDataModel();
    model['HirerId'] = _me.state.record.hirerId;

    _me.setIsSaving(true);

    const data = await saveHirer(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Details saved');
      PubSub.publish(HirerUtils.Events.Saved, data);
    });
    setTimeout(function () {
      _me.setIsSaving(false);
    }, 500);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    if (this.state.errors.length > 0) {
      return <Errors {...this.state} />;
    }
    if (this.state.isGettingConfig) {
      return <GettingConfigMsg {...this.state} />;
    }
    if (this.state.isGettingRecord) {
      return <GettingRecordMsg {...this.state} />;
    }
    if (!this.state.configuration) {
      return null;
    }
    if (!this.state.record) {
      return null;
    }
    return (
      <>
        <Sidebar>
          <SidebarContent {...this.state} handleSaveClick={this.save} />
        </Sidebar>
        <ContentWithSidebarWrapper>
          <EditPanel
            {...this.state}
            handleChange={this.handleChange}
            handleTabSelect={this.handleTabSelect}
          />
        </ContentWithSidebarWrapper>
      </>
    );
  }
}

const Errors = ({ errors }) => {
  if (errors.length <= 0) {
    return null;
  }
  return (
    <Alert bsStyle='danger'>
      The following errors occurred:
      <ul>
        {errors.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </Alert>
  );
};

const EditPanel = (props) => {
  let eventKey = 1;
  return (
    <>
      <DashboardHeader>
        <Row>
          <Col sm={6}>
            <h2 className='m-b-none'>{props.record.companyName}</h2>
          </Col>
          <Col sm={6} className='text-right'>
            <table className='' style={{ width: 'auto', marginLeft: 'auto' }}>
              <tbody>
                <HeaderInfoTableRow
                  label='Created'
                  value={AppUtils.formatDateTimeUtc(
                    props.record.createdDateTime
                  )}
                />
                <HeaderInfoTableRow label='By' value={props.record.createdBy} />
              </tbody>
            </table>
          </Col>
        </Row>
      </DashboardHeader>
      <div className='tabs-container' style={{ padding: '10px' }}>
        <Tabs
          activeKey={props.activeTab}
          onSelect={props.handleTabSelect}
          id='hirer-edit-tabs'
          animation={false}
        >
          <Tab eventKey={eventKey++} title='Customer Details'>
            <CustomerDetailsTab {...props} />
          </Tab>
          <Tab eventKey={eventKey++} title='Users'>
            <UsersTab {...props} />
          </Tab>
          <Tab eventKey={eventKey++} title='Locations'>
            <LocationsTab {...props} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

const HeaderInfoTableRow = ({ label, value }) => {
  return (
    <tr>
      <td className='text-right p-w-sm'>
        <Muted>{label}</Muted>
      </td>
      <td className='text-left'>{value}</td>
    </tr>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the Customer is configured...
    </WellSmall>
  );
};

const GettingRecordMsg = ({ isGettingRecord }) => {
  if (!isGettingRecord) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the Customer is retrieved...
    </WellSmall>
  );
};

const HirerField = (props) => {
  return <Field.InCol {...props} disabled />;
};

const CustomerDetailsTab = ({ record }) => {
  return (
    <>
      <Row>
        <HirerField
          columns={6}
          name='CompanyName'
          label='Company Name'
          value={record.companyName}
          maxLength='100'
        />
      </Row>
      <Row>
        <HirerField
          columns={4}
          name='CompanyNumber'
          label='Company Number'
          value={record.companyNumber}
          maxLength='30'
        />
        <HirerField
          columns={8}
          name='CompanyEmailAddress'
          label='Company Email'
          value={record.companyEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <HirerField
          columns={4}
          name='MainContactName'
          label='Main Contact Name'
          value={record.mainContactName}
          maxLength='100'
        />
        <HirerField
          columns={8}
          name='MainContactEmailAddress'
          label='Main Contact Email'
          value={record.mainContactEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <HirerField
          columns={4}
          name='AddressLine1'
          label='Address Line 1'
          value={record.addressLine1}
          maxLength='100'
        />
        <HirerField
          columns={4}
          name='AddressLine2'
          label='Address Line 2'
          value={record.addressLine2}
          maxLength='100'
        />
        <HirerField
          columns={4}
          name='AddressLine3'
          label='Address Line 3'
          value={record.addressLine3}
          maxLength='100'
        />
      </Row>
      <Row>
        <HirerField
          columns={4}
          name='Town'
          label='Town/City'
          value={record.town}
          maxLength='100'
        />
        <HirerField
          columns={4}
          name='County'
          value={record.county}
          maxLength='100'
        />
        <HirerField
          columns={4}
          name='Postcode'
          value={record.postCode}
          maxLength='10'
        />
      </Row>
      <Row>
        <HirerField
          columns={4}
          name='Region'
          value={record.region}
          maxLength='100'
        />
      </Row>
    </>
  );
};

const UsersTab = ({ record }) => {
  return (
    <>
      <HirerUserTable hirerId={record.hirerId} />
    </>
  );
};

const SidebarContent = (props) => {
  const { record } = props;
  return (
    <>
      <div className='btn-container'>
        <SaveButton {...props} handleSaveClick={props.handleSaveClick} />
      </div>
      <SidebarData
        label='Created'
        value={AppUtils.formatDateTimeUtc(record.createdDateTime)}
      />
      <SidebarData label='Created By' value={record.createdBy} />
    </>
  );
};

const SaveButton = (props) => {
  const text = props.isSaving ? 'Saving' : 'Save';
  return (
    <Button
      bsStyle='info'
      bsSize='sm'
      block
      onClick={props.handleSaveClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {text}
    </Button>
  );
};

export default HirerEdit;
