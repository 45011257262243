import { Row } from 'react-bootstrap';
import { AlertWithIcon, Field } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { Trailer } from 'types/TrailerTypes';
import { NumberField as NumberFieldCommon } from './Components';

type TrailerSectionProps = {
  trailerId: string;
  setTrailerId: (value: string) => void;
  trailerOptions: {
    text: string;
    value: string;
  }[];
  trailer: Trailer;
};

export const TrailerSection = ({
  trailerId,
  setTrailerId,
  trailerOptions,
  trailer,
}: TrailerSectionProps) => {
  return (
    <>
      <Row>
        <Field.InCol
          columns={3}
          name={`TrailerId`}
          label={`Trailer (optional)`}
          options={trailerOptions}
          isSelect
          value={trailerId}
          onChange={(e: ICustomEvent) => {
            setTrailerId(e.target.value);
          }}
        />
      </Row>

      {trailerId && !trailer ? (
        <AlertWithIcon>
          Trailer not found. Please select a valid trailer.
        </AlertWithIcon>
      ) : null}

      {trailer ? (
        <>
          <h4>Trailer Costs</h4>
          <hr />
          <Row>
            <NumberField
              record={trailer}
              name='rentalCostPerDay'
              label='Rental'
            />
            <NumberField
              record={trailer}
              name='insuranceCostPerDay'
              label='Insurance'
            />
            <NumberField
              record={trailer}
              name='washAllowancePerDay'
              label='Wash Allowance'
            />
            <NumberField
              record={trailer}
              name='depreciationAllowancePerDay'
              label='Depreciation Allowance'
            />
            <NumberField
              record={trailer}
              name='damageAllowancePerDay'
              label='Damage Allowance'
            />
            <NumberField
              record={trailer}
              name='fridgeFuelCostPerDay'
              label='Fridge Fuel'
            />
            <NumberField
              record={trailer}
              name='maintenanceCostPerDay'
              label='Maintenance'
            />
            <NumberField
              record={trailer}
              name='trackingCostPerDay'
              label='Tracking'
            />
          </Row>
        </>
      ) : null}

      <hr />
    </>
  );
};

const NumberField = (props: {
  record: Trailer;
  name: keyof Trailer;
  label: string;
}) => {
  return <NumberFieldCommon {...props} type='trailer' />;
};
