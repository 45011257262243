import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import {
  useLinkedShiftsQuery,
  useReorderShiftMutation,
  useUpdateShiftChargeRateMutation,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, SpinIcon, WellSmall } from 'pages/Utils/react-utils';
import { LinkedShift, ReorderType } from 'types/ShiftTypes';
import { Button } from 'components/Button';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { ShiftNotesUi } from 'components/ShiftNotes';
import { ShiftSummaryRow } from 'components/ShiftSummaryRow';
import { Stack } from 'components/Stack';

export const LinkedShiftsUi = ({ shiftId }: { shiftId: string }) => {
  const query = useLinkedShiftsQuery(shiftId);

  const [isBeingReordered, setIsBeingReordered] = useState(false);
  const handleReorderClick = () => {
    setIsBeingReordered(!isBeingReordered);
  };

  const reorderShift = useReorderShiftMutation();

  const handleReorderShiftClick =
    (shiftId: string, reorderType: ReorderType) => () => {
      reorderShift.mutate(
        { shiftId, reorderType },
        {
          onSuccess: () => {
            AppUtils.displaySuccess('Success', 'Shift order updated');
            query.refetch();
          },
        }
      );
    };

  return (
    <>
      <h3>
        <Icon icon='fa-link' /> Linked Shifts
        {!query.isLoading && query.isFetching ? (
          <SpinIcon style={{ float: 'right' }} />
        ) : null}
        {!query.isLoading &&
        !query.isFetching &&
        query.isSuccess &&
        query.data.length > 1 ? (
          <Button
            bsSize='xs'
            bsStyle={isBeingReordered ? 'success' : 'primary'}
            onClick={handleReorderClick}
            style={{
              float: 'right',
            }}
          >
            {isBeingReordered ? 'Done' : 'Re-order'}
          </Button>
        ) : null}
      </h3>
      <hr />

      {query.isLoading ? (
        <div>
          <SpinIcon style={{}} /> Loading...
        </div>
      ) : null}

      <ErrorAlertForQueryOrNull error={query.error} isError={query.isError} />

      {query.isSuccess && query.data.length === 0 ? (
        <Alert bsStyle='info'>No linked shifts found.</Alert>
      ) : null}

      {query.isSuccess &&
        query.data.map((shift) => (
          <LinkedShiftSummary
            key={shift.shiftId}
            shift={shift}
            isBeingReordered={isBeingReordered}
            totalLinkedShifts={query.data.length}
            handleReorderClick={handleReorderShiftClick}
            isReorderPending={reorderShift.isLoading}
          />
        ))}

      {reorderShift.isError ? (
        <ErrorAlertForQueryOrNull
          error={reorderShift.error}
          isError={reorderShift.isError}
        />
      ) : null}
    </>
  );
};

const LinkedShiftSummary = ({
  shift,
  isBeingReordered,
  totalLinkedShifts,
  handleReorderClick,
  isReorderPending,
}: {
  shift: LinkedShift;
  isBeingReordered: boolean;
  totalLinkedShifts: number;
  handleReorderClick: (shiftId: string, reorderType: ReorderType) => () => void;
  isReorderPending: boolean;
}) => {
  return (
    <WellSmall>
      <ShiftSummaryRow
        label='Customer'
        value={
          <>
            {shift.hirerName}
            <a
              href={`/Shift/Edit/${shift.shiftId}`}
              target='_blank'
              rel='noreferrer'
              data-toggle='tooltip'
              title='Open shift in New Tab'
              onClick={() => {
                AppUtils.destroyTooltips();
              }}
              className='pull-right'
            >
              View Shift &raquo;
            </a>
          </>
        }
      />
      <Divider />

      {shift.isPrimaryShiftGeneralHaulage ? (
        <>
          <ShiftSummaryRow label='Status' value={shift.status} />
          <Divider />
          <ShiftSummaryRow
            label='Destination'
            value={shift.destinationPostcode}
          />
          <Divider />
        </>
      ) : null}

      {!shift.isPrimaryShiftGeneralHaulage ? (
        <>
          <ShiftSummaryRow
            label='Time'
            value={shift.shiftTime.substring(0, 5)}
          />
          <Divider />
          <ShiftSummaryRow label='Shift' value={shift.shiftName} />
          <Divider />
        </>
      ) : null}

      {isBeingReordered ? (
        <ShiftSummaryRow
          label=''
          value={
            <>
              {shift.order > 0 && shift.order <= totalLinkedShifts - 1 ? (
                <Button
                  bsSize='xs'
                  bsStyle='primary'
                  onClick={handleReorderClick(
                    shift.shiftId,
                    ReorderType.Increase
                  )}
                  disabled={isReorderPending}
                >
                  <Icon icon='fa-arrow-down' /> Down
                </Button>
              ) : null}{' '}
              {shift.order > 1 ? (
                <Button
                  bsSize='xs'
                  bsStyle='primary'
                  onClick={handleReorderClick(
                    shift.shiftId,
                    ReorderType.Decrease
                  )}
                  disabled={isReorderPending}
                >
                  <Icon icon='fa-arrow-up' /> Up
                </Button>
              ) : null}
            </>
          }
        />
      ) : null}

      {!isBeingReordered ? (
        <>
          <ChargeRateRow shift={shift} />
          <Divider />
          <NotesSection shiftId={shift.shiftId} />
        </>
      ) : null}
    </WellSmall>
  );
};

const Divider = () => <hr style={{ margin: '2px' }} />;

const ChargeRateRow = ({ shift }: { shift: LinkedShift }) => {
  const [ischargeBeingEdited, setIsChargeBeingEdited] = useState(false);
  const [chargeAmount, setChargeAmount] = useState<number | ''>(
    shift.chargeAmountFixedOverride
  );

  const queryClient = useQueryClient();
  const updateChargeRate = useUpdateShiftChargeRateMutation();

  const handleEditClick = () => {
    setIsChargeBeingEdited(true);
  };

  const handleSaveClick = () => {
    if (chargeAmount === '') {
      AppUtils.displayError(
        'Error',
        'Please enter a charge amount',
        false,
        'chargAmountValidation'
      );
      return;
    }

    updateChargeRate.mutate(
      {
        shiftId: shift.shiftId,
        chargeAmount,
      },
      {
        onSuccess: () => {
          AppUtils.displaySuccess('Success', 'Charge updated');
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.LinkedShifts],
          });
          setIsChargeBeingEdited(false);
        },
      }
    );
  };

  const handleCancelClick = () => {
    setIsChargeBeingEdited(false);
  };
  return (
    <>
      <ShiftSummaryRow
        label='Charge'
        value={
          <>
            {ischargeBeingEdited ? (
              <Stack
                direction='row'
                style={{ justifyContent: 'space-between' }}
              >
                <input
                  type='number'
                  step={0.01}
                  value={chargeAmount}
                  min={0}
                  style={{ width: '50%' }}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setChargeAmount('');
                    }

                    if (AppUtils.isNumeric(e.target.value)) {
                      setChargeAmount(parseFloat(e.target.value));
                    }
                  }}
                />
                <div className='m-b-xs'>
                  {updateChargeRate.isLoading ? null : (
                    <Button
                      bsSize='xs'
                      bsStyle='danger'
                      className='m-r-xs'
                      onClick={handleCancelClick}
                      disabled={updateChargeRate.isLoading}
                    >
                      X
                    </Button>
                  )}

                  <Button
                    bsSize='xs'
                    bsStyle='success'
                    onClick={handleSaveClick}
                    isLoading={updateChargeRate.isLoading}
                  >
                    Save
                  </Button>
                </div>
              </Stack>
            ) : (
              <>
                £{AppUtils.formatNumber(shift.chargeAmountFixedOverride)}
                <Button
                  bsSize='xs'
                  className='pull-right m-b-xs'
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              </>
            )}
          </>
        }
      />
      {updateChargeRate.isError ? (
        <>
          <hr />
          <ErrorAlertForQueryOrNull
            error={updateChargeRate.error}
            isError={updateChargeRate.isError}
          />
        </>
      ) : null}
    </>
  );
};

const NotesSection = ({ shiftId }: { shiftId: string }) => {
  return (
    <ShiftNotesUi
      shiftId={shiftId}
      displayToWorkerDefault
      addBtnSize='xs'
      showCreatedByColumn={false}
      showCreatedDateTimeColumn={false}
    />
  );
};
