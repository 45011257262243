import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { trailerApi } from 'apis/trailerApi';
import {
  CreateTrailerParams,
  GetTrailerSummaryParams,
  SaveTrailerParams,
} from 'types/TrailerTypes';

export const useCreateTrailerMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: CreateTrailerParams) => {
      return trailerApi.createTrailer(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getTrailersActive']);
    },
  });

  return mutation;
};

export const useEditTrailerMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: SaveTrailerParams) => {
      return trailerApi.editTrailer(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getTrailersActive']);
    },
  });

  return mutation;
};

export const useArchiveTrailerMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof trailerApi.archiveTrailer>[0]) => {
      return trailerApi.archiveTrailer(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getTrailersActive']);
    },
  });

  return mutation;
};

export const useGetTrailersActiveQuery = () => {
  return useQuery({
    queryKey: ['getTrailersActive'],
    queryFn: trailerApi.getTrailersActive,
    staleTime: 60000,
  });
};

export const useGetTrailerSummaryQuery = (params: GetTrailerSummaryParams) => {
  return useQuery({
    queryKey: ['getTrailerSummary', params.trailerId, params.shiftDate],
    queryFn: () => trailerApi.getTrailerSummary(params),
    enabled: !!params.trailerId,
  });
};
