import { useState, useEffect } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { GeneralHaulageDailyViewItemLinkedShift } from 'types/ShiftTypes';

// Define the type for the coordinates
interface Coordinates {
  lat: number;
  lng: number;
}
type GeneralHaulageDailyViewItemLinkedShiftWithWorker =
  GeneralHaulageDailyViewItemLinkedShift & { workerName: string };
type ShiftWithCoords = GeneralHaulageDailyViewItemLinkedShiftWithWorker &
  Coordinates;

// Define the props for the MapComponent
interface MapComponentProps {
  linkedShifts: GeneralHaulageDailyViewItemLinkedShiftWithWorker[][];
}

const apiKey = 'AIzaSyDcilHiLAaZ1_v33nhw_2wjYy-Nl5D2lI8';

// Function to geocode a postcode to coordinates
const geocodePostcode = async (
  postcode: string
): Promise<Coordinates | null> => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        postcode
      )}&key=${apiKey}`
    );

    if (response.data.status === 'OK') {
      const { lat, lng } = response.data.results[0].geometry.location;
      return { lat, lng };
    } else {
      console.error('Geocoding error:', response.data.status);
      return null;
    }
  } catch (error) {
    console.error('Geocoding error:', error);
    return null;
  }
};

const mapContainerStyle = {
  width: '100%',
  height: '600px',
};

const defaultCenter: Coordinates = {
  lat: 51.509865,
  lng: -0.118092,
};

export const MapComponent = ({ linkedShifts }: MapComponentProps) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const [coordinates, setCoordinates] = useState<ShiftWithCoords[]>([]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      const coordArray: ShiftWithCoords[] = [];

      for (const shiftArray of linkedShifts) {
        const shiftInProgress = shiftArray.find(
          (shift) => shift.status === ShiftUtils.Status.InProgress
        );
        const shiftToShow = shiftInProgress || shiftArray[0];

        if (shiftToShow) {
          const coords = await geocodePostcode(shiftToShow.destinationPostcode);
          if (coords) {
            const newShift: ShiftWithCoords = { ...shiftToShow, ...coords };
            coordArray.push(newShift);
          }
        }
      }

      setCoordinates(coordArray);
    };

    fetchCoordinates();
  }, [linkedShifts]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={coordinates[0] || defaultCenter}
    >
      {coordinates.map((shift, index) => (
        <Marker
          key={index}
          position={{ lat: shift.lat, lng: shift.lng }}
          label={shift.companyName + '-' + shift.workerName}
        />
      ))}
    </GoogleMap>
  );
};
